<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
  <b-card title="Teintes">
    <b-button
        href='/tints/add'
        variant="primary"
      >
        + Ajouter une teinte
      </b-button>
   <br /><br />
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="tints"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span
          v-if="props.column.field === 'name'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ props.row.name }}</span>
        </span>
       
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'price'">
          {{ props.formattedRow[props.column.field] }}€
        </span>
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item  @click="$router.push({name:'editTint', params: { id: props.row.id }})">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Modifier</span>
              </b-dropdown-item>
              <b-dropdown-item v-b-modal.modal-danger @click="selectedItem=props.row.id">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Supprimer</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Page 1 de
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> sur {{ props.total }} résultats </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="totalRows"
              v-model="currentPage"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
        <b-modal
          id="modal-danger"
          cancel-variant="outline-secondary"
          cancel-title="Non"
          ok-variant="danger"
          ok-title="Oui"
          @ok="deleteTint(selectedItem)"
          modal-class="modal-danger"
          centered
          title="Êtes-vous sûr ?"
        >
          <b-card-text>
            Attention, cette action est irréversible ! Êtes-vous sûr de vouloir supprimer cette teinte ?
          </b-card-text>
        </b-modal>
      </template>
    </vue-good-table>

  </b-card>
</b-overlay>
</template>

<script>
import {BModal,BOverlay, BButton, VBModal, BCard, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'
import {APIRequest} from '@simb2s/senseye-sdk'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardText,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BButton
  },
  data() {
    return {
      tints:[],
      pageLength: 50,
      totalRows:0,
      currentPage:1,
      dir: false,
      loading:true,
      selectedItem:null,
      columns: [
        {
          label: 'Nom',
          field: 'name',
        },
        {
          label: 'Prix',
          field: 'price',
        },
        {
          label: 'Action',
          field: 'action',
        }
        
      ],
      rows: [],
      searchTerm: '',

    }
  },
  watch:{
    currentPage:async function(){
      this.loading=true
      let offset=this.currentPage*this.pageLength
      let tints=await APIRequest.list('tints', null, null, this.pageLength,offset)
      this.tints=tints[0]
      this.loading=false
    }
  },
  methods:{
    async deleteTint(id)
    {
      this.loading=true
      try {
        await APIRequest.delete('tints',id)
        let tints=await APIRequest.list('tints')
        this.tints=tints[0]
        this.totalRows=tints[1]
        this.loading=false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'La teinte a été supprimée !',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        
      } catch (error) {
        this.loading=false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Une erreur est survenue.',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
        
      }
      
    }
  },
  async mounted()
  {
    let tints=await APIRequest.list('tints')
    this.tints=tints[0]
    this.totalRows=tints[1]
    this.loading=false
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
